import { Alert, Button, Checkbox, Col, DatePicker, Divider, Form, Input, Modal, Row, Space, Spin, TimePicker, Upload, Badge } from "antd";
import React, { useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import dayjs from "dayjs";
import { useEffect } from "react";
import CustomSearchDropDownSelect from "../../../../components/custom/CustomSearchDropDownSelect";
import ReusableFilePreview from "../../../../components/custom/ReusableFilePreview";
import ReusableFileUploadModal from "../../../../components/custom/ReusableFileUploadModal";
import useCampaign from "../../../../hooks/useCampaign";
import useTemplates from "../../../../hooks/useTemplates";
import useHttp from "../../../../hooks/useHttp";
import useConfigurePhoneField from "../../../../hooks/useConfigurePhone";
import SavedTemplatesSelect from "../../../outbound/SavedTemplatesSelect";
import { failureNotification, successNotification, failureNotificationWithBtn } from "../../../utils/CommonNotifications";
import { ConfigurePhone, FormInput, FromNumberComponent, ConstructFieldsList, ConfirmAlertModal } from "../../../utils/CommonVessels";

import { checkGivenDateIsAfterCurrentDate, getIsoFormattedDateAndTime } from "../../../utils/dateUtils";

import useAttachment from "../../../../hooks/useAttachment";
import {
  capitalizeFirstLetter,
  checkWhetherServiceIsAuthorized,
  getRandomNumber,
  getServiceObjectFromIntegId,
  mapToMediaList,
  openServiceNotAuthorizedModal,
} from "../../../utils/commonUtils";
import { checkIsRunningCampaign, MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN } from "../../utils/campaignUtils";
import CampaignActionArea from "./CampaignActionArea";
import { urlParams } from "../../../../constants/AppConstants";

const MINUTES_TO_DISABLE = 15;
const MONTHS_TO_DISABLE = 1;

function CampaignContent({ appProperties, initialValues, setInitialValues, setAppProperties, shrinkView, showOnlyStop }) {
  const [fileList, setFileList] = useState(initialValues?.mediaFileList ?? []);
  const [message, setMessage] = useState(initialValues?.message ?? "");

  const [isTestModalOpen, setIsTestModalOpen] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [campaignForm] = Form.useForm();
  const [recordsCount, setRecordsCount] = useState();

  return (
    <>
      <Form
        form={campaignForm}
        disabled={initialValues?.disabled}
        initialValues={{
          date: dayjs(),
          time: dayjs().add(MINUTES_TO_DISABLE, "minutes"),
          ...initialValues,
        }}
      >
        <Row className="p-3">
          <Col className="gutter-row me-4 rounded bg-white overflow-auto" span={shrinkView ? 24 : 13}>
            <SendCampaignForm
              setInitialValues={setInitialValues}
              initialValues={initialValues}
              templateId={templateId}
              setTemplateId={setTemplateId}
              campaignForm={campaignForm}
              isTestModalOpen={isTestModalOpen}
              setIsTestModalOpen={setIsTestModalOpen}
              message={message}
              setMessage={setMessage}
              fileList={fileList}
              setFileList={setFileList}
              appProperties={appProperties}
              setAppProperties={setAppProperties}
              shrinkView={shrinkView}
              recordsCount={recordsCount}
              setRecordsCount={setRecordsCount}
              showOnlyStop={showOnlyStop}
            />
          </Col>
          {!shrinkView && (
            <Col className="gutter-row rounded bg-white campaignCol" span={9}>
              <div className="row w-100 mx-auto p-3">
                <PreviewHeader message={message} setIsTestModalOpen={setIsTestModalOpen} appProperties={appProperties} />
                <div className="overflow-auto bg-white px-3" style={{ maxHeight: "70dvh" }}>
                  <div className="d-flex flex-column gap-3">
                    {message && (
                      <div className="w-100 hs-bg-v-light-grey h-auto text-wrap rounded p-4" style={{ wordBreak: "break-word" }}>
                        <pre className="previewCls">{message}</pre>
                      </div>
                    )}
                    <ReusableFilePreview
                      customClasses="bg-white"
                      shape={"card"}
                      direction={"vertical"}
                      setFileList={setFileList}
                      fileList={fileList}
                      initialValues={initialValues}
                    />
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
}

function PreviewHeader(props) {
  const { setIsTestModalOpen, message } = props;
  return (
    <>
      <div className="w-100 m-2 my-3">
        <div className="d-flex justify-content-between align-items-center">
          <span style={{ minHeight: "33px" }}>Preview</span>
          {message && (
            <Button style={{ borderColor: "#605bff", color: "#605bff" }} onClick={() => setIsTestModalOpen(true)}>
              Send Test
            </Button>
          )}
        </div>
      </div>
      <Divider />
    </>
  );
}

function SendTestModal(props) {
  const { isTestModalOpen, setIsTestModalOpen, loading, handleSendTestMessage } = props;
  return (
    <Modal
      centered
      footer={null}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0">Send test message</p>
          <div onClick={() => setIsTestModalOpen(false)} className="actionIconsSprite testModalCloseIcon" />
        </div>
      }
      className="testModal"
      open={isTestModalOpen}
      onCancel={() => setIsTestModalOpen(false)}
      closeIcon={null}
    >
      <div className="w-100 mt-3 px-1">
        <Form.Item colon={false} labelCol={{ span: 24 }} label="Send test SMS to" name="testTo">
          <Input size="large" className="bgLightWhite hs-focus-border w-100 rounded" />
        </Form.Item>
        <div className="d-flex w-100 justify-content-end">
          <Button
            size="large"
            style={{ borderRadius: "4px" }}
            className="hs-bg-violet d-flex align-items-center px-4 text-white"
            onClick={handleSendTestMessage}
            loading={loading}
          >
            <span className="hs-fs-12"> Send test</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function SendCampaignForm(props) {
  const {
    appProperties,
    fileList,
    setIsTestModalOpen,
    isTestModalOpen,
    setFileList,
    templateId,
    setTemplateId,
    message,
    setMessage,
    initialValues,
    setInitialValues,
    campaignForm,
    setAppProperties,
    shrinkView,
    setRecordsCount,
    recordsCount,
    showOnlyStop,
  } = props;
  const { sendCampaignMessage, loading, sendTestMessage } = useCampaign(appProperties);
  const [fromNumber, setFromNumber] = useState(initialValues?.fromNumber ?? "");
  const [integId, setIntegId] = useState("");
  const [savedNumbersList, setSavedNumbersList] = useState([]);
  const [csvData, setCsvData] = useState();
  const [module, setModule] = useState(initialValues?.moduleName);
  const [selectedPhoneFieldApi, setSelectedPhoneFieldApi] = useState(initialValues?.phoneFieldApi);
  const [filter, setFilter] = useState(initialValues?.filterId);
  const [moduleOptions, setModuleOptions] = useState(initialValues?.moduleOptions);
  const [filterOptions, setFilterOptions] = useState(initialValues?.filterOptions);
  const [disableModuleSelection, setDisableModuleSelection] = useState(false);
  const [showNotVisibleFilterBanner, setShowNotVisibleFilterBanner] = useState(false);
  const [resetFieldsWarningModalOpen, setResetFieldsWarningModalOpen] = useState(false);
  const [filterFromModule, setFilterFromModule] = useState(disableModuleSelection);

  const [selectedIds, setSelectedIds] = useState(urlParams.get("selectedIds"));
  const [excludedIds, setExcludedIds] = useState(urlParams.get("excludedIds"));
  const [isInvalidFilter, setIsInvalidFilter] = useState(false);

  const [testLoading, setTestLoading] = useState(false);
  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);

  useEffect(() => {
    if (shrinkView) {
      appProperties?.controller?.getAssociatedObjectType().then(function (currentModuleFromApplication) {
        if (currentModuleFromApplication) {
          setModule(currentModuleFromApplication);
          setDisableModuleSelection(true);
        }
      });
    }
  }, [appProperties]);

  useEffect(() => {
    if (filterOptions && filterOptions.length > 0) {
      // Adding the "label" key
      let updatedFilterOptionsArray = [];
      filterOptions?.forEach((filter) => {
        if (!filter?.label) {
          const obj = {
            ...filter,
            label: filter.filterName,
            value: filter.filterId,
          };
          updatedFilterOptionsArray.push(obj);
        }
      });
      if (updatedFilterOptionsArray && updatedFilterOptionsArray.length > 0) {
        setFilterOptions(updatedFilterOptionsArray);
      }
    }
  }, [filterOptions]);

  useEffect(() => {
    const integId = savedNumbersList.find((item) => item.phoneNumber === fromNumber)?.integId;
    setIntegId(integId);
  }, [fromNumber, savedNumbersList]);

  useEffect(() => {
    if (recordsCount >= MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.count) {
      setIsInvalidFilter(true);
    }
  }, [recordsCount]);

  const sendCampaign = useCallback(
    async (isUpdate = false) => {
      try {
        let serviceObject = getServiceObjectFromIntegId(appProperties, integId);
        if (!checkWhetherServiceIsAuthorized(appProperties, integId, serviceObject, false)) {
          openServiceNotAuthorizedModal(serviceObject?.right, appProperties);
          return;
        }
        const formFields = await campaignForm.validateFields();
        const csvFile = csvData?.file;
        const csvFileSize = csvData?.length;

        if (shrinkView) {
          formFields.campaignName = `${csvFile ? "csv" : module}_Campaign_${getRandomNumber(3)}`;
        }
        if (!message) {
          failureNotification("Alert", "Message body should not be empty");
        } else if (!fromNumber) {
          failureNotification("Alert", "From number cannot be empty");
        } else if (!initialValues?.csvFileId && !csvFile && !selectedPhoneFieldApi) {
          failureNotification("Alert", "Phone field cannot be empty");
        } else if (!formFields.campaignName) {
          failureNotification("Alert", "Campaign name should not be empty");
        } else if (!shrinkView && !csvData?.file && !filter) {
          setIsInvalidFilter(true);
        } else {
          const mediaFileList =
            fileList?.length > 0
              ? fileList.map((file) => {
                  return {
                    name: file.name,
                    url: file.url,
                    contentType: file?.contentType,
                    size: file?.size,
                  };
                })
              : null;

          const filterNameForShrinkView = `Selected ${module}`;

          const getFilterName = () => {
            if (shrinkView) return filterNameForShrinkView;
            if (csvFile) return csvFile?.name;
            return filterOptions?.find((item) => item?.filterId === filter)?.filterName;
          };
          var filterId = filter;
          let filterType = 0;
          let filterFromParams = null;
          if (shrinkView) {
            var filterObject = await appProperties?.controller?.getFilterObjectForShrinkView();
            filterId = filterObject?.filterId;
            filterType = filterObject?.filterType;
            filterFromParams = filterObject?.filter;
          }

          let payload = {
            campaignId: initialValues?.campaignId,
            messageScheduled: false,
            moduleName: csvFile ? null : module,
            phoneFieldApiName: csvFile ? null : selectedPhoneFieldApi,
            filterId: csvFile ? null : filterId,
            csvFileSize: csvFileSize,
            filterType: filterType,
            filterName: getFilterName(),
            csvFileId: initialValues?.csvFileId,
            from: fromNumber,
            mediaFileList: mediaFileList,
            campaignName: formFields?.campaignName,
            messageBody: message,
            templateId: templateId,
            selectedIds: shrinkView ? selectedIds : "",
            excludedIds: shrinkView ? excludedIds : "",
            filterObj: filterFromParams,
          };

          // if (recordsObj && recordsObj.length > 0) {
          //   payload = {
          //     ...payload,
          //     recordsObj: recordsObj,
          //   };
          // }

          if (formFields.date && formFields.time) {
            const scheduledTime = getIsoFormattedDateAndTime(formFields.date, formFields.time);
            payload = {
              ...payload,
              scheduledTime: scheduledTime,
              messageScheduled: true,
            };
          }
          const formData = new FormData();
          formData.append("data", JSON.stringify(payload));

          if (csvFile) {
            formData.append("file", csvFile);
          }
          if (isUpdate) {
            formData.append("action", "start");
          }
          const response = await sendCampaignMessage(formData, integId, isUpdate, shrinkView);
          return response;
        }
      } catch (e) {}
    },
    [
      campaignForm,
      csvData?.file,
      fileList,
      filter,
      fromNumber,
      initialValues?.campaignId,
      integId,
      message,
      module,
      sendCampaignMessage,
      templateId,
    ]
  );
  const handleSendTestMessage = () => {
    const { testTo } = campaignForm.getFieldsValue();
    setTestLoading(true);
    sendTestMessage({
      payload: {
        from: fromNumber,
        to: testTo,
        messageBody: message,
        templateId: templateId,
        associatedObjectType: module.value,
        mediaFileList: fileList
          ? fileList.map((file) => ({
              name: file.name,
              url: file.url,
            }))
          : [],
      },
      integId: integId,
      onSuccess: () => {
        setIsTestModalOpen(false);
      },
    }).finally(() => {
      setTestLoading(false);
    });
  };

  const handleResetFields = () => {
    if (!resetFieldsWarningModalOpen) {
      setResetFieldsWarningModalOpen(true);
    } else {
      setMessage("");
      setFileList([]);
      campaignForm.resetFields();
      handleResetFieldsWarningModalClose();
    }
  };

  const handleResetFieldsWarningModalClose = () => {
    setResetFieldsWarningModalOpen(false);
  };

  const handleClearCsvData = () => {
    if (setInitialValues) {
      setInitialValues((prev) => {
        return {
          ...prev,
          csvFileName: null,
          csvFileId: null,
        };
      });
      setModule(initialValues?.moduleOptions?.[0]);
    }
    setCsvData({});
  };

  const showActionArea = () => {
    return initialValues?.type === "scheduled" && checkGivenDateIsAfterCurrentDate(initialValues?.campaign?.scheduledTime);
  };

  return (
    <>
      <div className="w-100 mt-4">
        <ConfirmAlertModal
          onCancel={handleResetFieldsWarningModalClose}
          open={resetFieldsWarningModalOpen}
          onConfirm={handleResetFields}
          centered={true}
          alertIcon={<div className="actionIconsSprite alert-modal-icon info-icon" />}
          titleContent={"Discard changes"}
          modalContent={"All changes will be discarded if you reset. Are you sure you want to proceed?"}
          buttonConfirm={"Yes"}
        />
        {showActionArea() && (
          <CampaignActionArea
            showNotVisibleFilterBanner={showNotVisibleFilterBanner}
            confirmActionModalOpen={confirmActionModalOpen}
            setConfirmActionModalOpen={setConfirmActionModalOpen}
            loading={loading}
            appProperties={appProperties}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            sendCampaign={sendCampaign}
            showOnlyStop={showOnlyStop}
          />
        )}
        <Space size={"large"} direction="vertical" className={shrinkView ? "campaignContentContainerShrinkView" : "campaignContentContainer"}>
          <SendTestModal
            setIsTestModalOpen={setIsTestModalOpen}
            isTestModalOpen={isTestModalOpen}
            loading={testLoading}
            campaignForm={campaignForm}
            handleSendTestMessage={handleSendTestMessage}
          />
          {!shrinkView && <CampaignName />}
          <div className="d-flex flex-column gap-2">
            <span>From</span>
            <FromNumberComponent
              tabIndex={2}
              disabled={initialValues?.disabled}
              fromNumber={fromNumber}
              savedNumbersList={savedNumbersList}
              setSavedNumbersList={setSavedNumbersList}
              defaultSelectFirstOption={initialValues?.fromNumber ? false : true}
              handleFromNumberChange={setFromNumber}
              customClasses={"h-50 hs-fs-16 fw-bold hs-focus-border hs-bg-off-white"}
            />
          </div>

          {csvData?.length > 0 || initialValues?.csvFileName ? (
            <CsvPreview
              initialValues={initialValues}
              handleClearCsvData={handleClearCsvData}
              length={csvData?.length}
              name={csvData?.file?.name ?? initialValues?.csvFileName}
            />
          ) : (
            <RecipientSelect
              initialValues={initialValues}
              csvData={csvData}
              filterOptions={filterOptions}
              moduleOptions={moduleOptions}
              setModuleOptions={setModuleOptions}
              filter={filter}
              module={module}
              setFilterOptions={setFilterOptions}
              setCsvData={setCsvData}
              setFilter={setFilter}
              setModule={setModule}
              setSelectedPhoneFieldApi={setSelectedPhoneFieldApi}
              selectedPhoneFieldApi={selectedPhoneFieldApi}
              appProperties={appProperties}
              setAppProperties={setAppProperties}
              showNotVisibleFilterBanner={showNotVisibleFilterBanner}
              setShowNotVisibleFilterBanner={setShowNotVisibleFilterBanner}
              isInvalidFilter={isInvalidFilter}
              setIsInvalidFilter={setIsInvalidFilter}
              integId={integId}
              shrinkView={shrinkView}
              disableModuleSelection={disableModuleSelection}
              setInitialValues={setInitialValues}
              recordsCount={recordsCount}
              setRecordsCount={setRecordsCount}
              setSelectedIds={setSelectedIds}
              setFilterFromModule={setFilterFromModule}
              filterFromModule={filterFromModule}
            />
          )}
          <MessagingArea
            setTemplateId={setTemplateId}
            fileList={fileList}
            setFileList={setFileList}
            setMessage={setMessage}
            message={message}
            module={module}
            integId={integId}
            appProperties={appProperties}
          />

          <ScheduleArea initialValues={initialValues} />
          {initialValues?.source === "analytics" ? (
            <span className="pb-2" />
          ) : (
            <SendMessageArea
              handleResetFields={handleResetFields}
              loading={loading}
              sendCampaign={sendCampaign}
              isInvalidFilter={isInvalidFilter}
            />
          )}
        </Space>
      </div>
    </>
  );
}

const CsvPreview = (props) => {
  const { length, name, handleClearCsvData, initialValues } = props;
  return (
    <div className="d-flex">
      To : {name}
      {!initialValues?.disabled && (
        <>
          {length && <b className="ms-1">({length})</b>}
          <div className="ms-2 my-auto">
            <div className="actionIconsSprite closeIcon" onClick={handleClearCsvData} />
          </div>
        </>
      )}
    </div>
  );
};

function ScheduleArea({ initialValues }) {
  const [currentCheckedState, setCurrentCheckedState] = useState(initialValues?.type ?? "immediate");
  const [date, setDate] = useState(dayjs());

  const disableFutureDates = (current) => {
    return current && (current < dayjs().startOf("day") || current > dayjs().add(MONTHS_TO_DISABLE, "month"));
  };

  const disabledTime = () => {
    const now = dayjs();
    const next15Minutes = dayjs(now).add(15, "minutes");
    const isSameDate = now.isSame(dayjs(date), "date");
    return {
      disabledHours: () => {
        if (isSameDate) {
          return [...Array(next15Minutes.hour()).keys()];
        }
        return [];
      },
      disabledMinutes: (selectedHour) => {
        if (selectedHour === next15Minutes.hour() && isSameDate) {
          return [...Array(next15Minutes.minute()).keys()];
        }
        return [];
      },
      disabledSeconds: () => [],
    };
  };

  return (
    <div className="d-flex flex-column gap-6">
      <div className="mb-4">
        <b className="mt-2">Send</b>
        <ScheduleOption currentCheckedState={currentCheckedState} setCurrentCheckedState={setCurrentCheckedState} />
      </div>
      {currentCheckedState === "scheduled" && (
        <div className={`d-flex flex-column gap-2 ${currentCheckedState !== "scheduled" ? "hidden" : "visibile"}`}>
          <span className="mb-2">Time & Date</span>
          <Row justify="start" style={{ height: 70 }}>
            <Col span={9} className="hs-bg-off-white d-flex me-3 rounded">
              <div className="d-flex align-items-center m-2 ps-1">
                <div className="actionIconsSprite calendarIcon" />
                <Form.Item name={"date"} rootClassName="mb-0">
                  <DatePicker
                    className="datePicker"
                    suffixIcon={null}
                    superNextIcon={null}
                    superPrevIcon={null}
                    showSecond={null}
                    bordered={false}
                    popupClassName="datePickerPanel"
                    allowClear={false}
                    value={date}
                    onChange={(e) => setDate(e)}
                    showToday={false}
                    disabledDate={disableFutureDates}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={9} className="hs-bg-off-white d-flex align-items-center mx-3 rounded">
              <div className="d-flex align-items-center m-2 ps-1">
                <div className="actionIconsSprite clockIcon" />
                <Form.Item name={"time"} rootClassName="mb-0">
                  <TimePicker
                    className="w-100 ps-2"
                    popupClassName="timePickerPopupPanel"
                    disabledTime={disabledTime}
                    suffixIcon={null}
                    showNow={false}
                    style={{ all: "unset" }}
                    allowClear={false}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

function ScheduleOption(props) {
  const { currentCheckedState, setCurrentCheckedState } = props;
  const options = [
    { value: "immediate", label: "Immediate" },
    {
      value: "scheduled",
      label: "Custom Date",
    },
  ];
  return (
    <div className="d-flex mt-2 gap-3">
      {options.map((option) => {
        return (
          <Checkbox
            key={option.value}
            className="schedule-check-box hs-fs-16"
            value={option.value}
            onChange={(e) => {
              setCurrentCheckedState(e.target.value);
            }}
            checked={currentCheckedState === option.value}
          >
            {option.label}
          </Checkbox>
        );
      })}
    </div>
  );
}

function SendMessageArea(props) {
  const { sendCampaign, loading, handleResetFields, isInvalidFilter } = props;
  return (
    <div className="d-flex justify-content-end pb-4">
      <div className="d-flex align-items-center gap-4">
        <Button danger className="hs-border-10 med-button px-3" onClick={() => handleResetFields()}>
          <span className="px-3">Reset</span>
        </Button>
        <Button
          className="hs-bg-violet hs-border-10 big-button hs-fs-16 px-3"
          type="primary"
          name="submitButton"
          tabIndex={6}
          onClick={() => sendCampaign()}
          loading={loading}
          disabled={isInvalidFilter}
        >
          <span className="px-3">Launch</span>
        </Button>
      </div>
    </div>
  );
}

function MessagingArea(props) {
  const { TextArea } = Input;
  const { setMessage, message, module, fileList, setFileList, appProperties, setTemplateId, integId } = props;

  const [modalVisible, setModalVisible] = useState(false);

  const handleTextChange = (e) => {
    setMessage(e.target.value);
  };

  function handleMessage(message) {
    setMessage(message);
  }

  function handleMedia(mediaFile) {
    setFileList(mediaFile ? mapToMediaList(mediaFile, false) : []);
  }

  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  return (
    <div className="d-flex flex-column gap-2 ">
      <span>Message</span>
      <ReusableFileUploadModal
        integId={integId}
        visible={modalVisible}
        setVisible={setModalVisible}
        fileList={fileList}
        setFileList={setFileList}
        source={"campaign"}
      />
      <div className="hs-bg-off-white hs-focus-border rounded">
        <MessagingAreaHeader
          fileList={fileList}
          setFileList={setFileList}
          appProperties={appProperties}
          setTemplateId={setTemplateId}
          module={module}
          setMessageAreaLoading={setMessageAreaLoading}
          handleMessage={handleMessage}
          setModalVisible={setModalVisible}
          handleMedia={handleMedia}
        />
        <Spin spinning={messageAreaLoading}>
          <TextArea
            tabIndex={1}
            className="hs-fs-14"
            placeholder={"Your text goes here"}
            size="large"
            autoSize={{ maxRows: 8, minRows: 8 }}
            bordered={null}
            value={message}
            onChange={handleTextChange}
          />
        </Spin>
      </div>
    </div>
  );
}

function MessagingAreaHeader(props) {
  const { setMessageAreaLoading, handleMessage, module, setTemplateId, setModalVisible, handleMedia } = props;

  return (
    <div
      className="d-flex h-50 bg-light justify-content-between rounded-top d-flex align-items-center px-3"
      style={{
        border: ".5px solid #D8DDDE",
      }}
    >
      <div className="actionIconsSprite mediaUploadIconModern" onClick={() => setModalVisible(true)} />
      <SavedTemplatesSelect
        setTemplateId={setTemplateId}
        tabIndex={5}
        openAddTemplateDrawer={true}
        moduleName={module}
        handleMessage={handleMessage}
        handleMedia={handleMedia}
        setMessageAreaLoading={setMessageAreaLoading}
        showPreview={false}
        refetchOnModuleChange={true}
        fetchOnlyByModule={true}
      />
    </div>
  );
}

const RecipientSelect = React.memo((props) => {
  const {
    filter,
    setFilter,
    module,
    setModule,
    setSelectedPhoneFieldApi,
    selectedPhoneFieldApi,
    appProperties,
    setCsvData,
    setFilterOptions,
    filterOptions,
    csvData,
    initialValues,
    setAppProperties,
    setModuleOptions,
    moduleOptions,
    showNotVisibleFilterBanner,
    setShowNotVisibleFilterBanner,
    setIsInvalidFilter,
    isInvalidFilter,
    integId,
    disableModuleSelection,
    shrinkView,
    recordsCount,
    setRecordsCount,
    setSelectedIds,
  } = props;

  const { fetchFields, fetchModules, fetchFiltersByModule, fetchFilterInfoByFilterId } = useTemplates();
  const { fetchPhoneFieldConfiguration } = useConfigurePhoneField();

  const [loading, setLoading] = useState(false);
  const [phoneFieldLoading, setPhoneFieldLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [isImportCSVModalOpen, setIsImportCSVModalOpen] = useState(false);
  const [filterFromModule, setFilterFromModule] = useState(disableModuleSelection);
  const [phoneFields, setPhoneFields] = useState([]);
  const [phoneFieldConfiguration, setPhoneFieldConfiguration] = useState();
  const [moduleFieldsList, setModuleFieldsList] = useState(initialValues?.moduleFields);

  const openImportCSVModal = () => {
    setIsImportCSVModalOpen(true);
  };

  const filterRef = useRef(null);

  const ImportCSVButton = () => {
    return (
      <div className="d-flex align-items-center py-2 cursor-pointer" onClick={openImportCSVModal}>
        <div className="actionIconsSprite csvImportIcon" />
        <div className="hs-color-violet ms-2">Import CSV</div>
      </div>
    );
  };

  const CommonCampaignDropdown = () => {
    return <ImportCSVButton />;
  };

  const mapModuleOptions = (options) => {
    let labelledOptions = [];
    if (options) {
      options.forEach((ele) => {
        if (ele) {
          labelledOptions?.push({
            label: ele?.charAt(0)?.toUpperCase() + ele?.slice(1),
            value: ele,
          });
        }
      });
    }
    return labelledOptions;
  };

  useEffect(() => {
    if (moduleFieldsList) {
      const filteredPhoneFields = moduleFieldsList?.filter((field) => field.fieldType === "phone");
      if (filteredPhoneFields) {
        let filterPhoneOptionsArray = [];
        filteredPhoneFields?.forEach((filter) => {
          const obj = {
            ...filter,
            label: filter.fieldName,
            value: filter.fieldApiName,
          };
          filterPhoneOptionsArray.push(obj);
        });
        setPhoneFields(filterPhoneOptionsArray);
      }
    }
  }, [moduleFieldsList]);

  useEffect(() => {
    if (!phoneFieldConfiguration) return;
    const { primaryPhoneApiName, primaryPhoneLookUp, secondaryPhoneApiName, secondaryPhoneLookUp } = phoneFieldConfiguration?.data?.config || {};

    const getPhoneFieldObj = (apiName, lookUp) => {
      return phoneFields.filter((item) =>
        lookUp ? item?.lookupModuleFieldApiName === lookUp && item?.fieldApiName === apiName : item?.fieldApiName === apiName
      );
    };

    const primaryPhoneFieldObj = getPhoneFieldObj(primaryPhoneApiName, primaryPhoneLookUp);
    const secondaryPhoneFieldObj = getPhoneFieldObj(secondaryPhoneApiName, secondaryPhoneLookUp);

    const primaryPhoneDisplayLabel = primaryPhoneFieldObj[0]?.fieldName || "";
    const primaryPhoneAssociatedModule = primaryPhoneFieldObj[0]?.lookupModuleField || "";

    const secondaryPhoneOneDisplayLabel = secondaryPhoneFieldObj[0]?.fieldName || "";
    const secondaryPhoneAssociatedModule = secondaryPhoneFieldObj[0]?.lookupModuleField || "";

    let phoneConfigurationRuleName = "";

    if (primaryPhoneDisplayLabel) {
      phoneConfigurationRuleName = primaryPhoneAssociatedModule
        ? `${primaryPhoneAssociatedModule.slice(0, 7)} > ${primaryPhoneDisplayLabel}`
        : primaryPhoneDisplayLabel;
    }

    if (secondaryPhoneOneDisplayLabel) {
      let secondaryPhoneModuleName = secondaryPhoneAssociatedModule ? secondaryPhoneAssociatedModule.slice(0, 7) + " > " : "";
      phoneConfigurationRuleName = phoneConfigurationRuleName
        ? `${phoneConfigurationRuleName} (OR) ${secondaryPhoneModuleName}${secondaryPhoneOneDisplayLabel}`
        : `${primaryPhoneDisplayLabel} (OR) ${secondaryPhoneOneDisplayLabel}`;
    }

    const phoneConfigurationRuleFieldObj = {
      fieldApiName: "Hello-Send-Phone-Configuration-Rule",
      fieldId: "Hello-Send-Phone-Configuration-Rule-Id",
      fieldName: capitalizeFirstLetter(phoneConfigurationRuleName),
      fieldType: "phone",
      moduleName: appProperties.module,
      label: capitalizeFirstLetter(phoneConfigurationRuleName),
      value: "Hello-Send-Phone-Configuration-Rule",
    };

    if (phoneFields && phoneFields.length > 0) {
      if (phoneFieldConfiguration?.data?.config) {
        let configData = phoneFieldConfiguration?.data?.config;
        if (configData?.primaryPhoneApiName || configData?.secondaryPhoneApiName) {
          const updatedData = phoneFields.filter((item) => item.fieldApiName !== phoneConfigurationRuleFieldObj.fieldApiName);
          setPhoneFields([phoneConfigurationRuleFieldObj, ...updatedData]);
        }
      }
      if (initialValues?.phoneFieldApi) {
        setSelectedPhoneFieldApi(initialValues?.phoneFieldApi);
        setPhoneFieldLoading(false);
      } else if (phoneFieldConfiguration?.data?.config) {
        let configData = phoneFieldConfiguration?.data?.config;
        if (configData?.primaryPhoneApiName || configData?.secondaryPhoneApiName) {
          if (phoneConfigurationRuleFieldObj?.fieldApiName) {
            setSelectedPhoneFieldApi(phoneConfigurationRuleFieldObj.fieldApiName);
            setPhoneFieldLoading(false);
          }
        }
      } else {
        // setSelectedPhoneFieldApi(appProperties?.controller?.primaryPhoneApiName || "Phone");
        setPhoneFieldLoading(false);
      }
    }
  }, [phoneFieldConfiguration]);

  useEffect(() => {
    console.log("disableModuleSelection >>>>>>>>>>>>", disableModuleSelection);
    if (appProperties?.leftServiceId) {
      appProperties.controller
        .fetchContactsCount(fetchFilterInfoByFilterId, appProperties, integId, module)
        .then(function (recordCount) {
          if (recordCount) {
            setRecordsCount(recordCount);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    appProperties?.controller?.getAssociatedObjectId().then(function (recordIds) {
      if (recordIds) {
        setSelectedIds(recordIds);
      }
    });
  }, [selectedPhoneFieldApi, appProperties?.leftServiceId]);

  useEffect(() => {
    if (initialValues?.recordsObj) {
      setRecordsCount(initialValues?.recordsObj.length);
    }
  }, [initialValues?.recordsObj]);

  const mapAndSetModuleOptions = useCallback(
    (modules, fromFetchApi) => {
      const labelledOptions = mapModuleOptions(modules);
      setModuleOptions(labelledOptions);
      if (!shrinkView && fromFetchApi) {
        setModule(initialValues?.moduleName ?? modules[0]);
      }
      setFilterFromModule(true);
    },
    [initialValues?.moduleName]
  );

  const mapAndSetFilterOptions = useCallback(
    (filters, moduleName) => {
      // let filterOptionsArray = [];

      appProperties?.controller?.fetchDefaultFilterOptions(moduleName).then(function (filterOptionsArray) {
        filters?.forEach((filter) => {
          const obj = {
            ...filter,
            label: filter.filterName,
            value: filter.filterId,
          };
          filterOptionsArray.push(obj);
        });
        let filter = null;
        const filterId = initialValues?.filterId;
        if (!initialValues?.sharedFilter && initialValues?.disabled) {
          filterOptionsArray.push({
            filterId: initialValues?.filterId,
            filterName: initialValues?.filterName,
            label: initialValues?.filterName,
            value: initialValues?.filterId,
            invisible: true,
          });
        }
        setFilterOptions(filterOptionsArray);
        if (filterId) {
          filter = filterOptionsArray.find((obj) => obj.value === filterId);
          const selectedFilterId = filter?.value ?? filterOptionsArray?.[0]?.value;
          setFilter(selectedFilterId);
          handleFilterSelect(selectedFilterId);
        }
      });
    },
    [appProperties?.controller]
  );

  useEffect(() => {
    const modules = initialValues?.moduleOptions;
    if (modules) {
      mapAndSetModuleOptions(modules, false);
    } else {
      if (disableModuleSelection) {
        setLoading(false);
        setFilterFromModule(true);
      } else {
        setLoading(true);
        setPhoneFieldLoading(true);
        fetchModules(appProperties, false, "")
          .then((response) => {
            if (response?.success) {
              mapAndSetModuleOptions(response?.data, true);
              if (response?.moduleFields) {
                setModuleFieldsList(response?.moduleFields);
              }
            } else if (response) {
              failedToFetch("modules");
            }
          })
          .catch((e) => {
            console.error(e);
            failedToFetch("modules");
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [fetchModules, initialValues?.moduleName, setModule, appProperties?.leftServiceId]);
  const failedToFetch = (type) => {
    setLoading(false);
    failureNotification(null, `Something went wrong while fetching ${type}`);
  };

  useEffect(() => {
    if (filter && !initialValues?.sharedFilter) {
      const selectedFilter = filterOptions.find((item) => item?.filterId === filter?.toString());
      if (selectedFilter?.invisible) {
        setShowNotVisibleFilterBanner(true);
      } else {
        setShowNotVisibleFilterBanner(false);
      }
    }
  }, [filterOptions]);

  useEffect(() => {
    const moduleName = module?.value ?? module;
    if (moduleName && appProperties?.serviceInstalled) {
      if (shrinkView && !filterFromModule) return;
      setLoading(true);
      setPhoneFieldLoading(true);
      fetchFiltersByModule(appProperties, moduleName)
        .then((res) => {
          mapAndSetFilterOptions(res?.filters, moduleName);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // }
  }, [appProperties?.serviceInstalled, fetchFiltersByModule, initialValues?.campaign?.filterId, module, setFilter]);

  useEffect(() => {
    const moduleName = module?.value ?? module;
    if (moduleName && appProperties?.serviceInstalled && filterFromModule) {
      setPhoneFieldLoading(true);
      fetchFields(appProperties, moduleName)
        .then((res) => {
          let filterPhoneOptionsArray = ConstructFieldsList(res);
          if (filterPhoneOptionsArray) {
            setPhoneFields(filterPhoneOptionsArray);
            fetchPhoneFieldConfiguration({ ...appProperties, module: moduleName }, "GET").then(function (phoneFieldConfigurationFromApp) {
              if (phoneFieldConfigurationFromApp) {
                setPhoneFieldConfiguration(phoneFieldConfigurationFromApp);
              }
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
          setPhoneFieldLoading(false);
        });
    }
    // }
  }, [appProperties, module, filterFromModule]);

  const handleModuleSelect = (ele) => {
    setModule(ele);
    setFilterFromModule(true);
    setFilterOptions([]);
    setSelectedPhoneFieldApi(null);
    setFilter();
  };

  const handlePhoneFieldSelection = (ele) => {
    setSelectedPhoneFieldApi(ele);
  };

  useEffect(() => {
    if (isInvalidFilter) {
      if (filterRef) {
        filterRef.current?.focus();
      }
    }
  }, [isInvalidFilter]);

  const handleFilterSelect = async (ele) => {
    setFilterLoading(true);
    let count;
    const filter = filterOptions.find((item) => item?.value === ele);
    if (!filter?.count) {
      fetchFilterInfoByFilterId(appProperties, integId, module, ele, "", "", appProperties?.leftServiceId)
        .then((res) => {
          count = res?.count;
          if (count && count <= MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.count) {
            setIsInvalidFilter(false);
          } else {
            setIsInvalidFilter(true);
          }
        })
        .catch((err) => {
          failureNotification(null, "Failed to fetch filter info");
        })
        .finally(() => {
          setFilterOptions((prev) =>
            prev.map((item) => {
              if (item?.value === ele) {
                item.count = count;
                return item;
              } else {
                return item;
              }
            })
          );
          setFilter(ele);
          setFilterLoading(false);
        });
    } else {
      const count = filterOptions?.find((item) => item?.value === ele)?.count;
      if (count && count >= MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.count) {
        setIsInvalidFilter(true);
      } else {
        setIsInvalidFilter(false);
      }
      setFilter(ele);
      setFilterLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      <span>To</span>
      <Row className="w-100">
        <Col span={10}>
          <div id="selectModule" className="w-100">
            <CustomSearchDropDownSelect
              customProps={{
                searchBoxPlaceHolder: "Search modules",
                disabled: (disableModuleSelection && shrinkView) || initialValues?.disabled ? "disabled" : "",
                bordered: null,
                suffixIcon: loading ? (
                  <div className="actionIconsSprite dropDownLoading" />
                ) : (
                  <div className="actionIconsSprite fromNumberDropDownIcon" />
                ),
                value: module,
                tabIndex: 3,
                className: "hs-bg-off-white hs-focus-border h-50 w-100 rounded",
                onSelect: handleModuleSelect,
              }}
              listEndComponent={<CommonCampaignDropdown />}
              selectSource="modules"
              options={moduleOptions}
            />
          </div>
        </Col>
        <Col span={1}></Col>
        <Col span={11}>
          <div id="selectModulePhoneField" className="w-100">
            <CustomSearchDropDownSelect
              customProps={{
                searchBoxPlaceHolder: "Search phone fields",
                placeholder: "Select phone field",
                disabled: initialValues?.disabled,
                bordered: null,
                value: phoneFieldLoading ? "" : selectedPhoneFieldApi,
                suffixIcon: phoneFieldLoading ? (
                  <div className="actionIconsSprite dropDownLoading" />
                ) : (
                  <div className="actionIconsSprite fromNumberDropDownIcon" />
                ),
                tabIndex: 3,
                className: "hs-bg-off-white hs-focus-border h-50 w-100 rounded",
                onSelect: handlePhoneFieldSelection,
              }}
              selectSource="phone fields"
              options={phoneFields}
            />
          </div>
        </Col>
        <Col span={1}></Col>
        <Col span={1}>
          <ConfigurePhone
            module={module}
            setSelectedPhoneFieldApi={setSelectedPhoneFieldApi}
            setPhoneFieldConfiguration={setPhoneFieldConfiguration}
            phoneFieldConfiguration={phoneFieldConfiguration}
            phoneFields={phoneFields}
          />
        </Col>
      </Row>
      {(initialValues?.campaignStatusName !== "paused" && (initialValues?.selectedIds || initialValues?.excludedIds)) ||
      shrinkView ||
      (initialValues?.recordsObj && initialValues?.recordsObj?.length > 0) ? (
        <Row
          className={`mt-2 bg-grey w-100 hs-bg-off-white ${
            initialValues?.recordsObj && initialValues?.recordsObj?.length > 0 ? "disabled-look" : ""
          }`}
          style={{ height: "50px", color: "#030229" }}
        >
          <Col flex="auto">
            <div className="d-flex align-items-center h-100 ms-3">Custom selection</div>
          </Col>
          <Col flex="auto">
            <div className="d-flex justify-content-end align-items-center h-100 me-2">
              <Badge
                color="#F0F0FF"
                style={{ color: "#030229" }}
                count={recordsCount}
                overflowCount={MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.count}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="mt-2">
          <Col flex="auto">
            <div id="selectFilter" className="w-100">
              <CustomSearchDropDownSelect
                customProps={{
                  searchBoxPlaceHolder: "Search filters",
                  disabled: initialValues?.disabled,
                  placeholder: "Select filter",
                  bordered: null,
                  suffixIcon: filterLoading ? (
                    <div className="actionIconsSprite dropDownLoading" />
                  ) : (
                    <div className="actionIconsSprite fromNumberDropDownIcon" />
                  ),
                  value: filterLoading ? "" : filter,
                  tabIndex: 3,
                  className: `hs-bg-off-white hs-focus-border h-50 w-100 rounded ${isInvalidFilter && "hs-focus-border-red"}`,
                  ref: filterRef,
                  onSelect: handleFilterSelect,
                }}
                options={filterOptions}
                selectSource="filters"
              />
            </div>
          </Col>
        </Row>
      )}

      {showNotVisibleFilterBanner && <Alert message="Filter is not visible to you" banner />}
      <Alert
        rootClassName={`bg-transparent border border-0 hs-fs-12 p-0 ${isInvalidFilter && "hs-color-red"}`}
        message={MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.message}
        type="info"
        showIcon={true}
        icon={<div className={`staticIconsSprite ${isInvalidFilter ? "infoIconRed" : "infoIconViolet"}`} />}
      />
      <div></div>
      <ImportCSVModal
        csvData={csvData}
        setIsImportCSVModalOpen={setIsImportCSVModalOpen}
        isImportCSVModalOpen={isImportCSVModalOpen}
        setCsvData={setCsvData}
        setIsInvalidFilter={setIsInvalidFilter}
      />
    </div>
  );
});

const ImportCSVModal = (props) => {
  const { isImportCSVModalOpen, setIsImportCSVModalOpen, setCsvData, setIsInvalidFilter } = props;
  const [errCsvData, setErrCsvData] = useState();
  const [csvParsing, setCsvParsing] = useState(false);
  const { downloadAFile } = useAttachment();

  const closeImportCSVModal = () => {
    setErrCsvData();
    setCsvParsing(false);
    setIsImportCSVModalOpen(false);
  };
  const { Dragger } = Upload;

  const handleChange = async (info) => {
    successNotification("CSV contacts imported. Start sending campaigns.");
    const { file } = info;
    try {
      setCsvParsing(true);
      const Papa = await import("papaparse");
      const text = await file.text();
      Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        encoding: "UTF-8",
        complete: (parsedData) => {
          if (
            parsedData?.errors?.length > 0 ||
            !parsedData?.meta?.fields?.includes("Name") ||
            !parsedData?.meta?.fields?.includes("PhoneNumber")
          ) {
            console.error("Error parsing CSV:", parsedData.errors, parsedData?.meta?.fields);
            setErrCsvData({
              length: parsedData.data?.length,
              file: file,
              error: "Ensure the CSV file includes Name and Phone number fields.\nPlease refer to the sample CSV file for guidance.",
            });
            setCsvParsing(false);
            return;
          }

          if (parsedData.data?.length > MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.count) {
            setErrCsvData({
              length: parsedData.data?.length,
              file: file,
              error: MAXIMUM_SUPPORTED_RECORDS_FOR_CAMPAIGN.message,
            });
            setCsvParsing(false);
            return;
          } else {
            setIsInvalidFilter(false);
            setCsvData({ length: parsedData.data?.length, file: file });
            setCsvParsing(false);
            setIsImportCSVModalOpen(false);
          }
        },
        error: () => setCsvParsing(false),
      });
    } catch (e) {
      console.error(e);
      setCsvParsing(false);
    }
  };

  const handleDownload = async () => {
    downloadAFile("CAMPAIGN_SAMPLE_CSV_FILE", "sample.csv");
  };

  return (
    <Modal
      cancelButtonProps={{
        hidden: true,
      }}
      okButtonProps={{
        hidden: true,
      }}
      className="importCSVModal"
      closeIcon={null}
      maskClosable={false}
      title={null}
      open={isImportCSVModalOpen}
    >
      <div className="row mx-auto w-100">
        <div className="d-flex justify-content-between">
          <div className="my-auto hs-fs-18">Import CSV</div>
          <div className="actionIconsSprite modalCloseIcon" onClick={closeImportCSVModal} />
        </div>
        <div className="row w-100 mx-auto px-3">
          <div className="mt-4 px-0 mb-4">
            <Dragger
              showUploadList={false}
              openFileDialogOnClick
              multiple={false}
              beforeUpload={() => false}
              className={`w-100 h-50 ${errCsvData?.length > 0 ? "csvError" : "csvDragger"}`}
              type="drag"
              accept="text/csv"
              onChange={handleChange}
            >
              <div className="my-5">
                <div className="d-flex justify-content-center w-100">
                  {csvParsing ? <div className="actionIconsSprite dropDownLoading" /> : <div className="actionIconsSprite csvUploadIcon" />}
                </div>
                <div className="hs-fs-12 hs-color-dark-grey">
                  Drag and drop file here or
                  <span className="hs-color-violet"> Click to upload</span>
                </div>
                <div className="d-flex mt-2 justify-content-center">
                  <div className="csvErrorDescription hs-fs-12">{errCsvData?.error && <span>{errCsvData?.error}</span>}</div>
                </div>
              </div>
            </Dragger>
            <div className="hs-color-dark-grey hs-fs-12">Supported format : CSV</div>
          </div>
          <div className="w-100 hs-bg-light-grey hs-border-dark-grey my-4">
            <Row>
              <Col span={22}>
                <div className="ps-3 py-2 my-1">
                  <div>CSV file requirement :</div>
                  <div className="hs-fs-12 hs-color-dark-grey lh-sm">
                    Please ensure that the CSV file includes both the "PhoneNumber" and "Name" fields. Attached is a <b>Sample CSV</b> file for
                    your reference.
                  </div>
                </div>
              </Col>
              <Col span={2}>
                <div className="d-flex align-items-center h-100">
                  <div className="actionIconsSprite sampleCsvDownloadIcon" onClick={handleDownload} />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
};

function CampaignName() {
  return (
    <FormInput
      formItemName={"campaignName"}
      formItemLabel={"Campaign Name"}
      formItemRules={[
        {
          required: true,
          message: <span className="position-absolute">Required!</span>,
        },
      ]}
      formExtraContent="Only for analytics purposes, not exposed to your customers."
      instantfocus={true}
    />
  );
}

export default CampaignContent;
