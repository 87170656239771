import { urlParams } from "./../constants/AppConstants";
import SendSmsDemo from "../assets/video/ZohoCRMSendSmsVideo.mp4";
import Thumbnail from "../assets/images/ZohoCrmSendsms-thumbnail.png";

function getAllUrlParams() {
  var allUrlParams = new URLSearchParams(window.location.search);
  var resourceObj = {};
  for (var key of allUrlParams.keys()) {
    resourceObj[key] = allUrlParams.get(key);
  }
  var resourceStr = JSON.stringify(resourceObj);
  return encodeURIComponent(resourceStr);
}

const ZohoCRM = {
  primaryPhoneApiName: "Phone",
  hideAutoFocusMessageArea: true,
  secondaryPhoneApiName: "Mobile",
  serviceInitialized: false,
  selectedEntityIdsFromZohoCRM: "",
  selectedEntityModuleFromZohoCRM: "",
  configData: {},
  service: "Zoho CRM",
  isConversationViewInSendSMS: true,
  isCustomModuleEnabled: true,
  workFlowIconInTemplates: true,
  isWorkflowTableFormat: false,
  redirectUrl: true,
  contactNumber: true,
  api: "api/v2/",
  addUserButton: true,
  associatedLookup: true,
  automationPageHelpLink: "https://help.oapps.xyz/portal/en/kb/articles/automate-sms-in-zoho-crm",
  automationHelpVideoOne: "https://www.youtube.com/embed/g2G-HGeOiyI?si=Wouh6BQfScDbaqEi",
  automationHelpVideoTwo: "https://www.youtube.com/embed/41GWpzW8jXw?si=4zKwo2AKhpJ2Vwpu",
  automationHelpVideoThree: "https://www.youtube.com/embed/g2G-HGeOiyI?si=Wouh6BQfScDbaqEi",
  automationHelpHeadContentFour: "Automate SMS on Specific Date in Zoho CRM",
  automationHelpContentFour: "Send automatic SMS reminders for important dates like appointments, renewals, and more",
  automationHelpVideoFour: "https://www.youtube.com/embed/BEifAzENT_M",
  automationHelpHeadContentFive: "Automate SMS Reminders from Zoho CRM",
  automationHelpContentFive: "Set up automatic SMS reminders for meetings directly in Zoho CRM",
  automationHelpVideoFive: "https://www.youtube.com/embed/BVLCy3R6C6E",
  sendSmsVideoComponent: <video width={"100%"} height={"100%"} className="mt-2" poster={Thumbnail} src={SendSmsDemo} controls />,
  sideBarExtend: {
    homeSideBar: false,
    serviceSideBar: true,
    inboxSidebar: true,
    conversationSidebar: false,
    sendSmsSidebar: true,
    sendBulkSmsSidebar: false,
    phoneSidebar: false,
    pagesSidebar: false,
    templateSideBar: true,
    workFlowSideBar: true,
    usersSideBar: true,
    workflowTabName: "Webhook",
    workflowTabSingularName: "Webhook",
    hideSMSTabsInSideBar: false,
    campaignSidebar: true,
    campaignOverviewSidebar: true,
  },
  isPhoneConfigurationEnabled: true,
  isBulkSMSPhoneConfigureDefaultValue: "Contacts",
  redirectPage: "https://crm.zoho.com/",
  campaignLink: true,
  associateLookupField: true,
  filterTypeMap: {},

  loadScripts: function () {
    return new Promise((resolve, reject) => {
      resolve("https://live.zwidgets.com/js-sdk/1.2/ZohoEmbededAppSDK.min.js");
    });
  },
  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, domain, companyId, userId } = appProperties;
      var leftServiceId = urlParams.get("leftServiceId");
      var allUrlParams = getAllUrlParams();
      var serviceOrigin = urlParams.get("serviceOrigin");
      var hrefUrl = `/pricing?productId=102&groupName=OMNI_CHANNEL&domain=${domain}&hash=${hash}&leftServiceId=${leftServiceId}&osyncId=${osyncId}&urlParams=${allUrlParams}&companyId=${companyId}&userId=${userId}&serviceOrigin=${serviceOrigin}`;

      resolve(hrefUrl);
    });
  },
  channelRedirectUrl: function () {
    return new Promise((resolve, reject) => {
      var rightServiceId = urlParams.get("rightServiceId");
      var leftServiceId = urlParams.get("leftServiceId");
      var userId = urlParams.get("userId");
      var companyId = urlParams.get("companyId");
      var module = urlParams.get("module");
      var channelRedirectUrl = `leftServiceId=${leftServiceId}&service=pdOmni&domain=org{companyId}&action=settings&userId=${userId}&companyId=${companyId}&onInstall=true&version=2&resource=${module}&rightServiceId=${rightServiceId}`;
      resolve(channelRedirectUrl);
    });
  },

  getUrlParamsForRedirection: function (isNewWindow, appProperties) {
    return new Promise((resolve, reject) => {
      urlParams.delete("sideBar");
      urlParams.delete("topBar");
      urlParams.delete("fullView");
      var rightServiceId = appProperties?.rightServiceId;
      if (isNewWindow) {
        urlParams.delete("service");
        urlParams.append("service", "zohocrmredirect");
      }
      if (!rightServiceId) {
        rightServiceId = urlParams.get("rightServiceId");
      }

      if (rightServiceId) {
        urlParams.delete("rightServiceId");
        urlParams.append("rightServiceId", rightServiceId);
      }

      let companyId = appProperties?.companyId;
      let userId = appProperties?.userId;

      if (!companyId) {
        companyId = urlParams.get("companyId");
      }
      if (companyId) {
        urlParams.delete("companyId");
        urlParams.append("companyId", companyId);
        urlParams.append("domain", "org" + companyId);
      }

      if (!userId) {
        userId = urlParams.get("userId");
      }

      if (userId) {
        urlParams.delete("userId");
        urlParams.append("userId", userId);
      }

      resolve(urlParams.toString());
    });
  },

  getAssociatedObjectId: function () {
    return new Promise((resolve, reject) => {
      if (ZohoCRM.selectedEntityIdsFromZohoCRM) {
        console.log(
          "dinku Currrent Module typeof ZohoCRM.selectedEntityIdsFromZohoCRM >>>>>>>>>>>>>>>>>>>",
          typeof ZohoCRM.selectedEntityIdsFromZohoCRM
        );
        console.log("dinku Currrent Module ZohoCRM.selectedEntityIdsFromZohoCRM >>>>>>>>>>>>>>>>>>>", ZohoCRM.selectedEntityIdsFromZohoCRM);
        console.log(
          "dinku Currrent Module ZohoCRM.selectedEntityIdsFromZohoCRM.length  >>>>>>>>>>>>>>>>>>>",
          ZohoCRM.selectedEntityIdsFromZohoCRM.length
        );
        if (typeof ZohoCRM.selectedEntityIdsFromZohoCRM === "object") {
          if (ZohoCRM.selectedEntityIdsFromZohoCRM != undefined && ZohoCRM.selectedEntityIdsFromZohoCRM.length > 0) {
            if (ZohoCRM.selectedEntityIdsFromZohoCRM.length > 1) {
              resolve(ZohoCRM.selectedEntityIdsFromZohoCRM.map((val) => val).join(","));
            } else {
              resolve(ZohoCRM.selectedEntityIdsFromZohoCRM[0]);
            }
          } else {
            resolve("");
          }
        } else {
          resolve(ZohoCRM.selectedEntityIdsFromZohoCRM);
        }
      } else {
        resolve("");
      }
    });
  },
  getUserData: function () {
    var companyId = urlParams.get("companyId");
    var emailId = urlParams.get("email");
    var userId = urlParams.get("userId");
    var domain = urlParams.get("domain");
    var companyOrgId = companyId;
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: companyId,
        userId: userId,
        companyOrgId: companyOrgId,
        domain: domain,
        emailId: emailId,
      };
      resolve(userData);
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      console.log(
        "dinku Currrent Module embeddedApp ZohoCRM.selectedEntityModuleFromZohoCRM sdaf >>>>>>>>>>>>",
        ZohoCRM.selectedEntityModuleFromZohoCRM
      );
      resolve(ZohoCRM.selectedEntityModuleFromZohoCRM);
    });
  },
  getAssociatedObjectTypeInPlural: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(ZohoCRM.selectedEntityModuleFromZohoCRM);
    });
  },
  getFields: function (fieldsModule) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.META.getFields({ Entity: fieldsModule }).then(function (data) {
        resolve(data);
      });
    });
  },
  getAssociatedId: function (fieldsModule) {
    return new Promise((resolve, reject) => {
      this.getFields(fieldsModule).then(function (data) {
        // let parentModule = (moudule === module) ? "" : module;
        var lookupData = data?.fields;
        var fieldLabels = [];
        lookupData?.forEach(function (field) {
          var associateModuleData = {
            associateModuleField: field.field_label,
            associateIdModuleField: field.id,
            module: fieldsModule,
          };
          fieldLabels.push(associateModuleData);
        });
        resolve(fieldLabels);
      });
    });
  },

  fetchAssociateModuleByFields: function (moduleToFetch, moduleFieldsApiNameToFetch) {
    return new Promise((resolve, reject) => {
      this.getFields(moduleToFetch).then(function (data) {
        let parentModule = moduleToFetch === ZohoCRM.selectedEntityModuleFromZohoCRM ? "" : ZohoCRM.selectedEntityModuleFromZohoCRM;
        var lookupData = data?.fields;
        var fieldLabels = [];
        lookupData?.forEach(function (field) {
          if (field?.data_type === "phone") {
            var associateModuleData = {};
            if (moduleFieldsApiNameToFetch === moduleToFetch) {
              associateModuleData = {
                currentFieldModule: moduleToFetch,
                associateModuleField: field.field_label,
                associateModuleFieldAPIName: field.api_name,
                parentModule: parentModule,
                toNumber: "${!" + moduleFieldsApiNameToFetch + "." + field.api_name + "}",
              };
            } else {
              associateModuleData = {
                currentFieldModule: moduleToFetch,
                associateModuleField: field.field_label,
                associateModuleFieldAPIName: field.api_name,
                parentModule: parentModule,
                toNumber: "${!" + ZohoCRM.selectedEntityModuleFromZohoCRM + "." + moduleFieldsApiNameToFetch + "." + field.api_name + "}",
              };
            }
            fieldLabels.push(associateModuleData);
          }
        });
        resolve(fieldLabels);
      });
    });
  },

  fetchAssociateModule: function (moduleToFetch) {
    return new Promise((resolve, reject) => {
      this.getFields(moduleToFetch).then(function (data) {
        var lookupData = data?.fields;
        var fieldLabels = [];
        lookupData?.forEach(function (field) {
          if (field?.data_type === "lookup") {
            if (field.lookup && field.lookup.module && field.lookup.module.api_name) {
              var associateModuleData = {
                associateModuleField: field.field_label,
                associateFieldModule: field.lookup.module.api_name,
                associateFieldModuleAPIName: field.api_name,
                module: moduleToFetch,
              };
              fieldLabels.push(associateModuleData); // Collect field labels
            }
          }
        });
        resolve(fieldLabels);
      });
    });
  },
  fetchContactsCount: function (fetchFilterInfoByFilterId, appProperties, integId, module) {
    return new Promise((resolve, reject) => {
      resolve(ZohoCRM.selectedEntityIdsFromZohoCRM?.length);
    });
  },
  fetchContact: function (fetchData, appProperties, phoneFieldConfiguration) {
    return new Promise(async (resolve, reject) => {
      if (ZohoCRM.selectedEntityModuleFromZohoCRM) {
        var contactNumbersObj = await fetchContactFromZohoCRM(fetchData, appProperties, phoneFieldConfiguration);
        resolve(contactNumbersObj);
      }
    });
  },
  getWebhookContent: function (fetchData, fetchWebhookNotifications, appProperties, templateId) {
    return new Promise((resolve, reject) => {
      fetchWebhookNotifications(appProperties, templateId).then((response) => {
        resolve(response);
      });
    });
  },
  getSettingPreference: function (settingsObj) {
    return new Promise((resolve, reject) => {
      const keysToRemove = ["loggingPreference"];
      const filteredArray = settingsObj.filter((item) => !keysToRemove.includes(item.key));
      resolve(filteredArray);
    });
  },
  getDefaultModuleForAGivenModule: function (module) {
    return new Promise((resolve) => {
      if (module === "Deals" || module === "Tasks") {
        resolve(["Contact_Name", "Account_Name"]);
      }
      resolve("");
    });
  },
};
const fetchContactFromZohoCRM = async (fetchData, appProperties, phoneFieldConfiguration) => {
  try {
    const numbersList = [];
    let {
      primaryPhoneApiName,
      secondaryPhoneApiName,
      secondaryPhoneTwoApiName,
      primaryPhoneLookUp,
      secondaryPhoneLookUp,
      secondaryPhoneTwoLookUp,
    } = phoneFieldConfiguration?.data?.config || {};

    const records = await window.ZOHO.CRM.API.getRecord({
      Entity: ZohoCRM.selectedEntityModuleFromZohoCRM,
      RecordID: ZohoCRM.selectedEntityIdsFromZohoCRM,
    });

    const hasAssociatedModule = primaryPhoneLookUp || secondaryPhoneLookUp || secondaryPhoneTwoLookUp;

    if (hasAssociatedModule) {
      const phoneRecords = await getAllAssociatedModulesPhoneRecords(
        primaryPhoneLookUp,
        secondaryPhoneLookUp,
        secondaryPhoneTwoLookUp,
        records,
        primaryPhoneApiName,
        secondaryPhoneApiName,
        secondaryPhoneTwoApiName,
        numbersList
      );
      return phoneRecords;
    } else {
      const phoneRecords = await assemblePhoneRecords(
        records,
        primaryPhoneApiName,
        secondaryPhoneApiName,
        secondaryPhoneTwoApiName,
        numbersList
      );
      return phoneRecords;
    }
  } catch (err) {
    throw err;
  }
};

function assemblePhoneRecords(records, primaryPhoneApiName, secondaryPhoneApiName, secondaryPhoneTwoApiName, numbersList) {
  records?.data?.forEach((recordObj) => {
    const recordInfoObj = {
      id: recordObj["id"],
      module: ZohoCRM.selectedEntityModuleFromZohoCRM,
    };

    let phoneApiName = "Phone";
    if (primaryPhoneApiName) {
      phoneApiName = primaryPhoneApiName;
    }
    let phoneRecord = recordObj[phoneApiName];
    if (phoneRecord) {
      recordInfoObj.phone = phoneRecord;
      numbersList.push(recordInfoObj);
    } else {
      phoneApiName = secondaryPhoneApiName;
      phoneRecord = recordObj[phoneApiName];
      if (phoneRecord) {
        recordInfoObj.phone = phoneRecord;
        numbersList.push(recordInfoObj);
      }
    }
  });
  return Promise.resolve(numbersList);
}

async function getAllAssociatedModulesPhoneRecords(
  primaryPhoneLookUp,
  secondaryPhoneLookUp,
  secondaryPhoneTwoLookUp,
  records,
  primaryPhoneApiName,
  secondaryPhoneApiName,
  secondaryPhoneTwoApiName,
  numbersList
) {
  const fields = await ZohoCRM.getFields(ZohoCRM.selectedEntityModuleFromZohoCRM);

  const fetchAssociatedPhoneRecords = async (lookup, apiName) => {
    const field = fields.fields.find((field) => field.api_name === lookup);
    const module = field?.lookup?.module?.api_name;
    if (module) {
      const promises = records.data.map(async (recordObj) => {
        const recordId = recordObj[lookup]?.id;
        const associatedModuleRecord = await window.ZOHO.CRM.API.getRecord({
          Entity: module,
          RecordID: recordId,
        });

        const recordInfoObj = {
          id: recordId,
          module: module,
        };
        const phoneValue = associatedModuleRecord?.data?.[0]?.[apiName];
        if (phoneValue) {
          recordInfoObj.phone = phoneValue;
          numbersList.push(recordInfoObj);
        }
        console.log(`${lookup} - fetchAssociatedPhoneRecords -phone value:`, phoneValue);
      });
      return Promise.all(promises);
    } else {
      return;
    }
  };

  await fetchAssociatedPhoneRecords(primaryPhoneLookUp, primaryPhoneApiName);
  if (numbersList.length === 0) {
    await fetchAssociatedPhoneRecords(secondaryPhoneLookUp, secondaryPhoneApiName);
  }
  if (numbersList.length === 0) {
    await fetchAssociatedPhoneRecords(secondaryPhoneTwoLookUp, secondaryPhoneTwoApiName);
  }

  return numbersList;
}

export default ZohoCRM;
